import React, { useEffect, useRef, useState } from 'react';
import './App.css';

import { initializeApp } from 'firebase/app';
import 'firebase/database';
import { getFirestore, collection, getDoc, doc, onSnapshot} from 'firebase/firestore';

import Scoreboard from './components/Scoreboard';
import Canvas from './components/Canvas';
import { getDatabase } from 'firebase/database';
import Roster from './components/Roster';
import Cueball from './components/Cueball';
import evowhitelogo from './assets/evosports-logo-white.png';
import BackgroundComponent from './components/BackgroundComponent';
import VenueLogoComponent from './components/VenueLogoComponent';
import { useDispatch, useSelector } from 'react-redux';
import { saveVenueData, watchVenue } from './slices/venueSlice';
import { watchTable } from './slices/tableSlice';
import { watchMatch } from './slices/matchSlice';
import Sponsors from './components/Sponsors';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDpVFrQFD9bF9asUMfoqPTDbd33seUs4mM",
  authDomain: "elosports.firebaseapp.com",
  databaseURL: "https://elosports.firebaseio.com",
  projectId: "elosports",
  storageBucket: "elosports.appspot.com",
  messagingSenderId: "793217318287",
  appId: "1:793217318287:web:530f4c8bb8b2404a789ffa"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const realtimedb = getDatabase(app);

// get the table name from the URL parameter ?table=<tableName>, default to "colin_1"
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const tableParam = urlParams.get('table');
const deviceName = urlParams.get('device');

function App() {
  // tableName should be from URL parameter ?table=<tableName>
  const cueballRef = useRef({});
  const [tableName, setTableName] = useState();
  const [isCommentating, setIsCommentating] = useState(false);
  const [isCommentatorNoBgmEnabled, setIsCommentatorNoBgmEnabled] = useState(false);
  const audioRef = useRef(null);
  const matchId = useSelector(state => state.table.matchId);
  const dispatch = useDispatch();

  useEffect(() => {
    var unsubscribeDevices = function() {};

    // if table Name is given, then do nothing
    // else if deviceName is given, then get the table name from the deviceName in the firebase firestore devices collection
    if (tableParam === null && deviceName !== null) {
      // get the table name from the deviceName in the firebase firestore devices collection
      const docRef = doc(db, `devices`, deviceName);
      // listening on docref if the device.table is changed, then update the tableName
      unsubscribeDevices = onSnapshot(docRef, async (doc) => {
        if (doc.exists() && doc.data().table) {
          setTableName(doc.data().table);
          console.log("Table Name via device id: ", doc.data().table);
        }
      });
    }
    else if (tableParam !== null) {
      setTableName(tableParam);
      console.log("Table Name via table param: ", tableParam);
    }

    return () => {
      unsubscribeDevices();
    }
  }, []);

  useEffect(() => {
    var unsubscribeRosters = function() {};
    var unsubscribeTables = function() {};

    if (!tableName) return;

    // getting the commentating status from the firebase firestore rosters collection
    // /rosters/<tableName>/members is an array, if this array exist and contains any item, then the commentating status is true
    if (tableName || tableParam) {
      const docRef = doc(db, `rosters`, tableName ? tableName : tableParam);
      unsubscribeRosters = onSnapshot(docRef, async (docObj) => {
        if (docObj.exists() && docObj.data().members && docObj.data().members.length > 0) {
          setIsCommentating(true);
        }
        else {
          setIsCommentating(false);
        }
      });
    }

    if (tableName || tableParam) {
      const docRef = doc(db, `tables`, tableName ? tableName : tableParam);
      unsubscribeTables = onSnapshot(docRef, async (docObj) => {
        if (docObj.exists() && docObj.data().commentatorNoBgm) {
          setIsCommentatorNoBgmEnabled(docObj.data().commentatorNoBgm);
          console.log("isCommentatorNoBgmEnabled: ", docObj.data().commentatorNoBgm);
        }
      });
    }

    const venueId = tableName ? tableName.split("_")[0] : "";
    dispatch(watchTable(tableName));
    dispatch(watchVenue(venueId));

    return () => {
      unsubscribeRosters();
      unsubscribeTables();
    }
  }, [tableName])

  useEffect(() => {
    if (!matchId) return;
    
    dispatch(watchMatch());
  }, [matchId])

  useEffect(() => {
    if (audioRef.current) {
      if (!isCommentating) {
        audioRef.current.play();
        console.log("audioRef.current.play()");
      } else {
        if (isCommentatorNoBgmEnabled) {
          audioRef.current.pause();
          console.log("audioRef.current.pause()");
        } else {
          audioRef.current.play();
          console.log("audioRef.current.play()");
        }
      }
    }
  }, [isCommentating, isCommentatorNoBgmEnabled]);

  return (
    <div className="overlay-container">

      <audio 
        ref={audioRef}
        style={{ display: 'none' }} 
        src="https://firebasestorage.googleapis.com/v0/b/elosports.appspot.com/o/music%2Fevosports_bgm_1.mp3?alt=media" 
        loop 
      />
      <Scoreboard />
      <Sponsors />
      <Canvas tableId={tableName}/>
      <Cueball x={0} y={0} size={200} className="hidden z-[4000]" tableId={tableName} thisRef={cueballRef}/>
      <Roster tableName={tableName} position={{ top: 50, left: 4 }} />
      <VenueLogoComponent position={{ bottom: 25, left: 25, width: 150 }} venue={tableName ? tableName.split("_")[0] : ""} />
      <VenueLogoComponent position={{ bottom: 25, right: 25, width: 150 }} venue={tableName ? tableName.split("_")[0] : ""} />
    </div>
  );
}

export default App;
